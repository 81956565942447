import React from "react"
import SplashWithIllustration from "@src/components/SplashWithIllustration"
import Layout from "@src/components/Layout"
import Section from "@src/components/Section"
import Text from "@src/components/Text"
import Button from "@src/components/Button"
import FeatureGrid from "@src/components/FeatureGrid"
import getAppUrl from "@src/utils/getAppUrl"
import track from "@src/utils/track"

import StripeIcon from "@src/icons/v2/stripe_datasource.png"
import YoutubeIcon from "@src/icons/v2/youtube_datasource.png"
import GithubIcon from "@src/icons/v2/github_datasource.png"
import TwitterIcon from "@src/icons/v2/twitter_datasource.png"
import SlackIcon from "@src/icons/v2/slack_datasource.png"
import GSheetsIcon from "@src/icons/v2/gsheets_datasource.png"
import AsanaIcon from "@src/icons/v2/asana_datasource.png"
import GAIcon from "@src/icons/v2/ga_datasource.png"
import PintrestIcon from "@src/icons/v2/pintrest_datasource.png"
import HubspotIcon from "@src/icons/v2/hubspot_datasource.png"

import UnifiedApiIcon from "@src/icons/v2/unified_api.svg"
import AggregatedDataIcon from "@src/icons/diversity.svg"
import UnifiedIcon from "@src/icons/v2/unified_api_icon.svg"
import CustomDashboardsIcon from "@src/icons/v2/custom_dashboard.svg"
import MultipleDataSources from "@src/icons/screenshots/multiple-data-sources.png"
import OG from "@src/icons/og/OG_Multiple_Datasources.jpeg"

import Splash from "./components/Splash"
import ImageRow from "./components/ImageRow"
import Row from "../components/Row"
import Features from "./components/Features"
import * as styles from "./styles.module.scss"

const IndexPage = ({ location }) => {
  const appUrl = getAppUrl.hook(location)
  const templateUrl = `${getAppUrl(location)}/dashboard/marketplace/samples`
  return (
    <Layout
      title="Multiple DataSources"
      description="Handle multiple data sources with ease!"
      url="https://canonic.dev/features/multiple-data-sources/"
      location={location}
      ogImage={OG}
    >
      <Splash
        title="Handle Multiple Data Sources With Ease! "
        subtitle="Unifying data, merging sources from multiple applications, & transforming data are tasks in themselves that can be a roadblock for many organizations."
        icon={TemplateScroll}
      />
      <Row>
        <ImageRow
          title="A Unified API for multiple data sources"
          subtitle="This unified API will allow you to query, transform, mutate data
          and integrate with multiple different frontends. Here is a sample
          project for you to get started.👇"
          icon={UnifiedApiIcon}
        />
        <Features
          horizontal
          numPerRow={2}
          features={[
            {
              title: "Customer Information dashboard",
              link: {
                title: "Learn More",
                url: "https://dev.to/canonic/how-to-hydrate-data-from-stripe-and-hubspot-1gf2",
              },
              subtitle:
                "Hydrate data from these different sources saving you the trouble of manual integration. Walkthrough the guide to learn how to hydrate references from multiple sources.",
            },

            {
              title: "Github PR Dashboard",
              link: {
                title: "Learn More",
                url: "https://dev.to/canonic/a-github-pr-dashboard-with-reactjs-and-material-ui-ae5",
              },
              subtitle:
                "Track your PR's on Github in the form of a dashboard. A step-by-step guide to building your own custom PR Dashboard in just a matter of minutes.",
            },
            {
              title: "Postgres dashboard",
              link: {
                title: "Learn More",
                url: "https://dev.to/canonic/creating-a-dashboard-for-your-postgres-db-with-react-and-recharts-2ecm",
              },
              subtitle: `Learn how to build a dashboard on top of PostgresSQL database with React, Powered by Canonic"`,
            },
            {
              title: "Marketing dashboard",
              link: {
                title: "Learn More",
                url: "https://dev.to/canonic/how-to-build-a-twitter-youtube-analytics-dashboard-with-react-2p2d",
              },
              subtitle:
                "Want to monitor data from multiple social channels? We’ve got you covered. Get all your data in 1 dashboard and customize it as per your needs",
            },
          ]}
        />
      </Row>
      <Row>
        <h2 className={styles.title}>The perks that come along!</h2>
        <p className={styles.subtitle}>
          Unify you fragmented data, create dashboards and more
        </p>
        <Features
          numPerRow={3}
          features={[
            {
              icon: AggregatedDataIcon,
              title: "Aggregated Data",
              subtitle:
                "With support for multiple data sources and no-code integrations. It becomes incredibly easy to aggregate data and transform it into a precise API.",
            },
            {
              icon: UnifiedIcon,
              title: "Unified API",
              subtitle:
                "Each data source has its own API which leads to multiple API calls to separate services. Build a unified API to manage and integrate data sources with ease.",
            },
            {
              icon: CustomDashboardsIcon,
              title: "Custom Dashboards",
              subtitle:
                "Build dashboards that are backed by a unified/single API to manage all the multiple data sources. You can also link to existing databases to build quick APIs on top of them.",
            },
          ]}
        />
      </Row>
      <Row>
        <ImageRow
          reverse
          title="A Unified API for multiple data sources"
          screenshot={MultipleDataSources}
          subtitle={
            <ul>
              <li>Connect Multiple Datasources</li>
              <li>Instantly create Unified API on top of it </li>
              <li>
                Easily display desired fields from multiple data sources to a
                Dashboard
              </li>
            </ul>
          }
        />
      </Row>
    </Layout>
  )
}

const TemplateScroll = () => {
  return (
    <div className={styles.templateScroll}>
      <div className={styles.templateScrollRow}>
        <img src={SlackIcon} />
        <img src={StripeIcon} />
        <img src={PintrestIcon} />
        <img src={YoutubeIcon} />
        <img src={GSheetsIcon} />
      </div>
      <div className={styles.templateScrollRow}>
        <img src={AsanaIcon} />
        <img src={TwitterIcon} />
        <img src={GithubIcon} />
        <img src={GAIcon} />
        <img src={HubspotIcon} />
      </div>
    </div>
  )
}
export default IndexPage
